import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"

import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h1, h2 } from 'components/Theme/Copy';

import { GatsbyImage } from "gatsby-plugin-image"
import StyledReactModal from 'styled-react-modal'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { RiCloseFill } from "react-icons/ri";


const StyledModal = StyledReactModal.styled`
  opacity: ${p => p.opacity};
  transition: all 0.5s ease-out;
`;

const Window = styled.div`
  width:50vw;
  height:50vh;
  min-height:35rem;
  border-radius:0.2rem;
  position:relative;
  color:#fff;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius:0.5rem;
  ${media.lessThan("tablet")`
    width:90vw;
  `}
`;

const Copy = styled.div`
  padding:2rem;
  text-align:center;
`;

const Image = styled.div`
  position:absolute;
  width:100%;
  height:100%;
  overflow:hidden;
  object-fit:cover;
  z-index:-1;
  filter:brightness(60%);
`;

const Background = styled(GatsbyImage)`
  border-radius:0.5rem;
  ${media.lessThan("tablet")`
    height:100%;  
  `}
`;

const Headline = styled(h1)`
  font-size:6rem;
  margin:5rem 0 2rem 0;
  ${media.lessThan("tablet")`
    zoom:80%;
  `};
`;

const Description = styled(h2)`
  font-size:1.4rem;
  margin-bottom:5rem;
  ${media.lessThan("tablet")`
    margin-bottom:2rem;
  `};
`;

const Form = styled.div`
  font-size:2rem;
  input[type=email] {
    margin-right:1rem;
    border:0;
    font-size:1.3rem;
    padding:1rem 2rem;
  }
  button {
    text-transform:uppercase;
    background-color:${p => p.theme.brandPrimary};
    color:#fff;
    padding:1rem 2rem;
    font-size:1.3rem;
    letter-spacing:0.2rem;
    box-shadow: 0 0.4rem 1.5rem 0.2rem rgba(0, 0, 0, .2);
    cursor:pointer;
    border:2px solid background-color:${p => p.theme.brandPrimary};
    transition:transform .2s, background-color .2s;
    &:hover, &:focus, &:active {
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
      background-color:${p => p.theme.brandPrimary + 'df'};
    }
    &:focus {
      outline:0;
    }
    ${media.lessThan("tablet")`
      margin-top:1rem;
    `}
  }
  .message {
    padding:2rem;
    ${p => p.theme.typography200};
  }
`;

const CloseButton = styled(RiCloseFill)`
  position:absolute;
  top:0.5rem;
  right:0.5rem;
  font-size:4rem;
  cursor:pointer;
`;

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div>
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <button onClick={submit}>
        Sign up
      </button>

      <div className="message" dangerouslySetInnerHTML={{ __html: message }} />
    </div>
  );
};

class Modal extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, atBottom: false, opacity: 0}

    this.handleScroll = this.handleScroll.bind(this);
  } 

  handleScroll(e) {
    const element = document.getElementById('main');
      if (!this.state.atBottom && this.isBottom(element)) {
        this.setState({atBottom: true, isOpen: true});
      }
      if (!this.isBottom(element) && !this.state.isOpen) {
        this.setState({atBottom: false, isOpen: false});
      }
  }
 
  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }
 
  isBottom(element) {
    return element.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount() {
    if (typeof window !== `undefined`) { 
      document.addEventListener('scroll', this.handleScroll);
    }
  }

  toggleModal = () => {
    this.setState({opacity: 0})
    this.setState({isOpen: !this.state.isOpen})
  }

  afterOpen = () => {    
    if (typeof window !== `undefined`) { 
      setTimeout(() => {
        this.setState({opacity: 1})
        localStorage.setItem('modalShown', true)
      }, 100);
    };
  }

  beforeClose = () => {
    return new Promise(resolve => {
      this.setState({opacity: 0})
      setTimeout(resolve, 300);
    });
  }

  render() {
    const { opacity, isOpen } = this.state
    let modalShown = false;

    if (typeof window !== `undefined`) { 
      modalShown = localStorage.getItem('modalShown')
    }

    return (
      <StaticQuery
          query={graphql`
            query ModalQuery {
              contentfulModal(active: {eq: true}) {
                title
                active
                description
                backgroundAsset {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          `}
          render={data => (
            <> { !modalShown && data.contentfulModal && data.contentfulModal.active &&
            <StyledModal
              isOpen={isOpen}
              onBackgroundClick={this.toggleModal}
              allowScroll={true}
              beforeClose={this.beforeClose}
              afterOpen={this.afterOpen}
              opacity={opacity}
              backgroundProps={{ opacity }}
              onEscapeKeydown={this.toggleModal}>
              <Window >
                <CloseButton onClick={this.toggleModal} />
                <Image>
                  <Background image={data.contentfulModal.backgroundAsset.gatsbyImageData} />
                </Image>
                <Copy>
                  <Headline>{data.contentfulModal.title}</Headline>
                  <Description>{data.contentfulModal.description}</Description>
                  <Form>
                  <MailchimpSubscribe
                      url="//cookwithcarbon.us2.list-manage.com/subscribe/post?u=0a1b7bd029a79d703abee2661&amp;id=0e48993f27"
                      render={({ subscribe, status, message }) => (
                        <CustomForm
                          status={status}
                          message={message}
                          onValidated={formData => subscribe(formData)}
                        />
                      )}
                    />
                  </Form>
                </Copy>
              </Window>
            </StyledModal>  
            } </>
          )}
        />
    )
  }
}

export default Modal;