import React from 'react'
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby"
import { media } from 'components/Theme/Styles';

const AnnouncmentBar = styled.div`
  font-weight:400;
  letter-spacing:0.15em;
  width:100%;
  display:flex;
  text-align:center;
  font-size:1.2rem;
  position:sticky;
  text-transform:uppercase;
  top:0;
  ${media.lessThan("tablet")`
    font-size:1.1rem;
  `}
`;

const AnnoucementCopy = styled.div`
  background-color:${p => p.theme.brandPrimary};
  padding:1rem;
  width:100%;
  transition: all 1000;
  font-weight:bold;

  
`;


const Annoucement = () => {
  
  const data = useStaticQuery(
    graphql`
      query AnnoucementQuery {
        contentfulDefaults {
          announcementBar
          announcementBarAlt
        }
      }
    `
  )

  return (
    <div>
      { data && 
        <AnnouncmentBar hidden={data.contentfulDefaults.announcementBar === ""}>
          <AnnoucementCopy>{data.contentfulDefaults.announcementBar}</AnnoucementCopy>
        </AnnouncmentBar>
      }
    </div>
  )
}



export default Annoucement