import React, { Component } from 'react'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import InlineMailchimpForm from "components/Helpers/InlineMailchimpForm";
import { body } from 'components/Theme/Copy';
import { GatsbyImage } from "gatsby-plugin-image"

import { FaInstagram, FaFacebook, FaYoutube, FaPinterest, FaTiktok } from "react-icons/fa";

const FooterContainer = styled.div`
  justify-content:center;
  align-items:center;
  display:flex;
  flex-direction:column;
`;

const FooterWrapper = styled.div `
  display:flex;
  justify-content:space-between;
  align-items:top;
  flex-direction:row;
  padding-top:5rem;
  width:100%;
  ${media.lessThan("tablet")`
    flex-direction:column;
    padding:4rem 0 0 0;
  `}

  ${media.greaterThan("desktop")`
    margin:0 8rem;
  `}

  ${media.greaterThan("widescreen")`
    margin:0 8rem;
    max-width:1980px;
  `}
`;

const FooterColumn = styled.div`
  color:#fff;
  padding:3rem;
  position:relative;
  min-width:20rem;
  ${p => p.textAlign && p.textAlign === 'right' ? `
    text-align:right;
  ` : null}
  ${media.lessThan("tablet")`
    text-align:center;
    justify-content:center;
  `}
`;

const Header = styled.div`
  ${p => p.theme.typography300};
  letter-spacing:0.2em;
  text-transform: uppercase;
  font-size:1.2rem;
  margin-bottom:1.5rem;
  padding-bottom:0.3rem;
  width:100%;
  display:block;
  color:${p => p.theme.brandPrimaryTextColor};
`;

const Subheader = styled.div`
  font-weight:bold;
  letter-spacing:0.2em;
  text-transform: uppercase;
  font-size:1rem;
  margin-top:2rem;
  
  width:100%;
  display:block;
  color:${p => p.theme.cardTextColor};
`;

const Item = styled(body)`
  font-size:1.2rem;
  line-height:2rem;
  padding:.3rem;
  display:block;
  text-transform:uppercase;
  color:${p => p.theme.cardTextColor};
  
  ${media.lessThan("tablet")`
    font-size:1.4rem;
  `}
`;

const ItemUSA = styled(Item)`
  color:${p => p.theme.brandPrimaryTextColor};
  display:inline-block;
`;

const Flag = styled.img`
  height: 1.4rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
  top: 0.2rem;
`;

const Subfooter = styled.div`
  color:${p => p.theme.brandPrimaryTextColor};
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  padding:4rem 3rem;
  margin-bottom:3rem;
  width:100%;
  ${media.lessThan("tablet")`
    flex-direction:column-reverse;
  `}

  ${media.greaterThan("desktop")`
    margin:0 8rem;
  `}

  ${media.greaterThan("widescreen")`
    margin:0 8rem;
    max-width:1980px;
  `}
`;

const Copyright = styled(Item)`
  color:${p => p.theme.brandPrimaryTextColor};
  padding:0;
  margin:0;
  ${media.lessThan("tablet")`
    font-size:1.4rem;
    margin-top:3rem;
  `}
`;

const PaymentLogos = styled(GatsbyImage)`
  width:20.4rem !important;
`;

const SocialIcons = styled.div`
  margin-bottom:1.5rem;
  a {
    margin-right:1.5rem;
    &:last-child {
      margin-right:0;
    }
    svg {
      color:${p => p.isLight ? '#ffffff' : p.theme.cardTextColor};
      width:2.4rem;
      height:2.4rem;
    }
  }
`;



class Footer extends Component {
    render() {
      return (
        <StaticQuery
          query={graphql`
            query FooterQuery {
              contentfulDefaults {
                ccIcons {
                  gatsbyImageData(width:200)
                }
                logo {
                  title
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          `}
          render={data => (
            <FooterContainer>
            <FooterWrapper>
              <FooterColumn>
                <Header>Company</Header>
                <Item><Link to={`/about`}>About</Link></Item>
                <Item><Link to={`/contact`}>Contact</Link></Item>
                <Item><a href="https://www.instagram.com/cookwithcarbon/" target="_blank" rel="noreferrer">@cookwithcarbon</a></Item>
                <Item><Link to={`/privacy`}>Privacy</Link></Item>
              </FooterColumn>
              <FooterColumn>
                <Header>Support</Header>
                <Item><Link to={`/docs/category/faq`}>FAQs</Link></Item>
                <Item><Link to={`/docs/category/cooking`}>Cooking</Link></Item>
                <Item><Link to={`/docs/category/care-and-maintenance`}>Care &amp; maintenance</Link></Item>
                <Item><Link to={`/docs/category/returns-and-warranty`}>Returns &amp; Warranty</Link></Item>
                <Item><Link to={"/account"}>My Account</Link></Item>
              </FooterColumn>
              <FooterColumn>
                <Header>Recipes</Header>
                <Item><Link to={`/recipes/traditional-pizza-dough`}>Traditional Pizza Dough</Link></Item>
                <Item><Link to={`/recipes/pesto-burrata-pizza`}>Basil Pesto and Burrata Pizza</Link></Item>
                <Item><Link to={`/recipes/prosciutto-truffle-pizza`}>Prosciutto Truffle Pizza</Link></Item>
                <Item><Link to={`/recipes/italian-sub-pizza`}>Italian Sub Pizza</Link></Item>
                <Item><Link to={`/recipes/smoked-turkey-swiss-pizza`}>Smoked Turkey and Swiss Pizza</Link></Item>
                <Item><Link to={`/recipes/garlic-oil`}>Toasty Garlic Oil</Link></Item>
                <Item><Link to={`/recipes/truffle-butter`}>Truffle Butter</Link></Item>
              </FooterColumn>
              <FooterColumn textAlign="right">
                <Header>Connect</Header>

                <SocialIcons>
                  <a href="https://www.instagram.com/cookwithcarbon/" target="_blank"><FaInstagram /></a>
                  <a href="https://www.facebook.com/cookwithcarbon/" target="_blank"><FaFacebook /></a>
                  <a href="https://www.youtube.com/channel/UC1SAZTLxdbdUFdkNzK10u2Q" target="_blank"><FaYoutube /></a>
                  <a href="https://www.pinterest.com/cookwithcarbon" target="_blank"><FaPinterest /></a>
                  <a href="https://www.tiktok.com/@cookwithcarbon" target="_blank"><FaTiktok /></a>
                </SocialIcons>

                <InlineMailchimpForm groupName="General->News, Updates, & Recipes">Get in touch</InlineMailchimpForm>
                
                <Subheader>Email us</Subheader>
                <Item><a href="mailto:help@cookwithcarbon.com" target="_blank" rel="noreferrer">help@cookwithcarbon.com</a></Item>

                <Subheader>Chat with us</Subheader>
                <Item>M-F 9AM - 5PM PST</Item>
              </FooterColumn>
            </FooterWrapper>
            
            <Subfooter>
              <Copyright>
                © Carbon Kitchen, LLC { new Date().getFullYear() }
                <ItemUSA><Flag alt="Made in California"
                                           src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAYAAAABOs/SAAABuElEQVQ4jcWUv2oUURTGf+feu5soO2hmRaIhlaCirY1gZ+0j6EP4ALa+gNqpjRaCgYD4ABaKhWApdq6awkh0srOT/TNzz7HY3ZjsCmpGzNccuBz4fd+55165cf32Rpb1khiV0AhUZUUInhgV7x0xKs4JPQJnjje59OgOK02jcA1qKA+bm9npc+dXKYoBZoYgGPO1rca3rMe1dy9ppwlqdbgkwXufD/qjZDQsf9lhBghEF3CmfHi8RtFqUuLqgPNgqqgqZkwSjmEiP6Eo0HDEsmLr9Rv8gjBwoQ6YgEySsX92NjPKMu/hjyxy+f490jSh3qQhAIgIMnWAITLTJcJCukTR+czzC1dYbio7Umu5CCCMyoqqqmBusdhNNvy6RaN1lKsv1mkvtVCbdfeX4E7nC1GVfn84PpmS9lKB0nnidpf3dx+y3Gowknp3LA+evupmWS8xIEzfrXeoKs5Nqjh2cKwmTVaePSH1yqAeOBf79LGLSLKbcG/dJwOFmByjUkPqrVcu66cudoHkd51TPzbv6GDg4ffsj8D/WHl4e/PWf2aOFRZPnjgUsIy2u3U/oYOB19KzGxzCHf8AQg+/KPWaFO0AAAAASUVORK5CYII="/> Made in USA</ItemUSA>
              </Copyright>
              <PaymentLogos image={data.contentfulDefaults.ccIcons.gatsbyImageData} />
            </Subfooter>
          </FooterContainer>
          )}
        />
      )
    }
}



export default Footer