import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Theme
import styled, { css } from 'styled-components';
import { media } from 'components/Theme/Styles';
import { h3, body } from 'components/Theme/Copy';

// Helpers
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isMobile } from 'react-device-detect';
import { useStyledDarkMode } from "gatsby-styled-components-dark-mode";
import useScrollPosition from '@react-hook/window-scroll'
import _ from 'lodash';

// Components
import { Link } from 'gatsby';
import { FiShoppingCart } from "react-icons/fi";

// Defaults
let IconSize = 2;
let LineLeft = 0;
let LineOffset = IconSize / 2;
let LineWidth = 1.8;
let MenuWidth = 30;

const Sticky = styled.div`
  position:sticky !important;
  top:-0.1rem;
  z-index:98;
  height:8rem;
  display:flex;
  align-items:center;
  width:100%;
  background-color:${p => p.isLight ? '#fff' : p.theme.brandPrimaryAlt };
`;

const HeaderWrapper = styled.div`
  display:flex;
  align-items:center;
  width:100%;
  margin:1.5rem 3rem;
  ${media.greaterThan("desktop")`
    margin:1.5rem 8rem;
  `}

  ${media.greaterThan("widescreen")`
    margin:1.5rem auto;
    max-width:1980px;
  `}
`;

const HeaderBar = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width:100%;
`;

const Center = styled.div`
  display:flex;
  justify-content:center;
  position:relative;
  top:0.2rem;
`;

const LogoWrapper = styled(Link)`
  margin:0 4rem;
  width:13rem;
  text-align:center;
`;

const DesktopHeaderLink = styled(Link)`
  color:${p => p.isLight ? '#333' : '#fff'};
  text-transform:uppercase;
  font-weight:300;
  font-size:1.6rem;
  
  position:relative;
  top:0.2rem;
  ${media.lessThan("tablet")`
    display:none;
  `}
`;

const FullLogo = styled.img`
  width:13rem;
  display:${p => p.isShown ? 'block' : 'none' };
`

const Logo = styled.img`
  width:13rem;
  
  display:${p => p.isShown ? 'block' : 'none' };
`

const Left = styled.div`
  display:flex;
  justify-content:flex-start;
  transition: margin 0.2s ease-out;
  flex-basis:33.33%;
`;

const Right = styled.div`
  display:flex;
  justify-content:flex-end;
  flex-basis:33.33%;
`;

const CartIcon = styled(FiShoppingCart)`
  color:${p => p.isLight ? '#000' : p.theme.cardTextColor};
  width:2.2rem;
  height:2.2rem;
  top:0.3rem;
  position:relative;
`;

const Menu = styled.div`
  ${p => p.open ? 'box-shadow: 0 0.4rem 1.5rem 1rem  rgba(0, 0, 0, .2)' : null };
  height: 100%; /* Fallback for browsers that do not support Custom Properties */
  width:100%;
  top:0;
  position:fixed;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  z-index:100;
  left:${p => p.open ? 0 : '-100%' };
  background-color:${p => p.theme.brandPrimaryAlt};
  transition: left 0.2s ease-in;
  padding:10rem 5rem;
  ${media.greaterThan("mobile")`
    width:${MenuWidth}rem;
    left:${p => p.open ? 0 : '-30rem' };
  `};
`;

const MenuItem = styled(h3)`
  color:#fff;
  text-transform:uppercase;
  font-size:2.2rem;
  text-align:left;
  display:block;
  margin-top:1rem;

  &:hover {
    color:${p => p.theme.brandPrimary};
  }

  a {
    &:hover {
      color:${p => p.theme.brandPrimary};
    }
  }

  ${media.greaterThan("mobile")`
    font-size:2.2rem;
  `};
`;

const OutsideMenuItem = styled(MenuItem)`
 
`;

const LogoMark = styled.img`
  width:5rem;
  height:5rem;
  margin:5rem auto;
`;

const HamburgerMenu = styled.div`
  display:flex;
  justify-content:flex-start;
  align-items:center;
`;

const HamburgerMenuLabel = styled(body)`
  display:${p => p.hide ? 'none' : 'block'};
  text-transform:uppercase;
  font-size:${IconSize - 0.2}rem;
  color:${p => p.isLight ? '#000' : p.theme.cardTextColor};
  margin-left:1rem;
  letter-spacing:0;
  font-weight:300;
  cursor:pointer;
  margin-top:0.4rem;
  font-family:Poppins;
  ${media.lessThan("tablet")`
    display:none;
  `};
`;

const HamburgerMenuIcon = styled.div`
  cursor: pointer;
  position: relative;
  width: ${IconSize}rem;
  height: ${IconSize}rem;
  transition: all 0.25s;
  transform: ${p => p.open ? 'rotate(90deg)' : null };
  z-index:105;
  margin-left:1rem;
  
  span {
    background-color:${p => p.isLight ? '#000' : '#fff'};
  }
`;

const HamburgerLine = css`
  content: '';
  display: block;
  position: absolute;
  left: ${LineLeft}rem;
  width: ${LineWidth}rem;
  height: 0.10rem;
  transform: rotate(0);
  transition: all 0.25s;
`;

const TopLine = styled.span`
  ${HamburgerLine};
  top: ${LineOffset - 0.1}rem;
  transform: ${p => p.open ? 'rotate(45deg) translateY(0rem)' : 'translateY(-0.2rem)' };
`;

const BottomLine = styled.span`
  ${HamburgerLine};
  bottom: ${LineOffset}rem;
  transform: ${p => p.open ? 'rotate(-45deg) translateY(0rem)' : 'translateY(0.7rem)' };
`;

const NavItem = styled.a`
  color:#fff;
  margin-left:20px;
  font-size:1.5rem;
  cursor:pointer;
  &:hover {
    color:${p => p.theme.brandPrimary};
  }
`;

const DarkModeToggle = styled.label`
  position:absolute;
  bottom:5rem;
  display:flex;
  justify-content: center;
  align-items:center;
`;

const DarkModeToggleLabel = styled.div`
  color:${p => p.theme.grey};
  margin-right:1rem;
  position:relative;
  top:0.1rem;
  text-transform:uppercase;

  ${media.lessThan("tablet")`
    font-size:1.6rem;
  `};
`;

const Header = (props) => {
  const { toggleDark, isDark } = useStyledDarkMode(true);
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(isDark);
  
  const [isPageScrolled, setPageIsScrolled] = useState(false);
  const scrollY = useScrollPosition(60 /*fps*/);

  const toggleDarkMode = () => {
    toggleDark()
    setDarkMode(!darkMode)
    toggleMenu()
  }

  const toggleCart = (e) => {
    if (typeof window !== `undefined`) {
      window.shopifyUI.openCart()
    }
  }

  const toggleMenu = (e) => {
    setOpen(!open)
  }


  if (scrollY > 0) {
    if (!isPageScrolled) {
      setPageIsScrolled(true)
    }
  } else {
    if (isPageScrolled) {
      setPageIsScrolled(false)
    }
  }

  const data = useStaticQuery(graphql`
    query {
      contentfulDefaults {
        logo {
          title
          gatsbyImageData(width:500)
        }
      }
    }
  `)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      if (isMobile) {
        let body = document.getElementsByTagName('body')
        if (open) {
          disableBodyScroll(body)
        } else {
          enableBodyScroll(body)
        }  
      }
    }
  })

  const headerLogoInverse = _.find(data.contentfulDefaults.logo, { title: 'Carbon-main-inverse' })
  const headerLogo = _.find(data.contentfulDefaults.logo, { title: 'Carbon-main' })
  const headerLogoImage = headerLogo.gatsbyImageData

  return (
      <Sticky isPageScrolled={isPageScrolled} isLight={ props.isLight }>
        <HeaderWrapper isPageScrolled={isPageScrolled}>
          <HeaderBar>
            <Left>
              <HamburgerMenu onClick={toggleMenu}>
                <HamburgerMenuIcon open={open}  isLight={props.isLight}>
                  <TopLine open={open} />
                  <BottomLine open={open} />
                </HamburgerMenuIcon>
                <HamburgerMenuLabel hide={open} isLight={props.isLight}>Menu</HamburgerMenuLabel>
              </HamburgerMenu>
            </Left>

            <Center>
              
                <DesktopHeaderLink isLight={props.isLight} to="/outdoor">Outdoor</DesktopHeaderLink>
                <LogoWrapper to="/" isPageScrolled={isPageScrolled}>  
                <FullLogo src="/carbon-logo-transparent-inverse.png" placeholder="none" loading="eager" alt="Carbon Logo" isShown={!props.isLight}  />
                <Logo src="/carbon-logo-transparent.png" placeholder="none" loading="eager" alt="Carbon Logo" isShown={props.isLight}  />
                </LogoWrapper>
                <DesktopHeaderLink isLight={props.isLight} to="/commercial">Commercial</DesktopHeaderLink>

            </Center>
            
            <Right>
              <NavItem onClick={toggleCart}><CartIcon isLight={props.isLight}/></NavItem>
            </Right>
          </HeaderBar>

          <Menu open={open} >
            <MenuItem><Link to="/outdoor">Outdoor</Link></MenuItem>
            <MenuItem><Link to="/commercial">Commercial</Link></MenuItem>
            
            <MenuItem><Link to="/recipes">Recipes</Link></MenuItem>
            <MenuItem><Link to="/about">About</Link></MenuItem>
            <MenuItem><Link to="https://shop.cookwithcarbon.com/account" target="_blank" rel="noopener norefferer">My Account</Link></MenuItem>
            <MenuItem><Link to="/support">Support</Link></MenuItem>

            <LogoMark src="/logo-mark.png" alt="Carbon Logo Mark"  />
          </Menu>
        </HeaderWrapper>
      </Sticky>
      
      
  ) 
}


export default Header