import { createGlobalStyle } from 'styled-components';
import { media } from 'components/Theme/Styles';

export const fontSystemFamilies = ["-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Lato", "Ubuntu", "Cantarell", "Helvetica Neue", "sans-serif"]
export const fontFamilies = ["Poppins", "Opti", ...fontSystemFamilies]

// Styles + Theme Globals
const theme = {
  white: '#FFFFFF',
  mediumWhite: '#F8F8F8',
  darkWhite: '#eeeeee',
  lightGrey: '#e8e7e7',
  grey: '#c6c8ca',
  mediumGrey: '#808080',
  darkGrey: '#5c5d5f',
  black: '#000',
  lightSuccess: '#FFFFFF',
  darkSuccess: '#000000',
  brandPrimary: '#C6AF8B',
  brandPrimaryDarker: '#af9f82',
  brandPrimaryContrast: '#6f5c3c',
  brandPrimaryAlt: '#282828',
  brandPrimaryLight: '#edeaff',
  brandSecondary: '#171F26',
  brandSecondaryLight: '#484847',
  brandSecondaryLinks: '#92A5B1',
  brandSecondaryHover: '#eaebf5',
  brandSecondaryBorder: '#2F363C',
  brandInfo: '#5b8edc',
  brandSuccess: '#5b8edc',
  brandWarning: '#ec663c',
  brandDanger: '#ec663c',
  brandLight: '#C2C3C4',
  headerColor: '#5b8edc',
  
  // general (in rem)
  containerWidth: 120,
  containerWidthSmall: 70,
  headerHeight: 9,
  maxWidth: 120,

  boxPadding: 6,
  boxGutterY: 12,

  fontFamily: `'${fontFamilies.join("', '")}'`,

  // forms
  formLabelFontSize: 1.2,
  formInputFontSize: 1.4,
  formInputLargeFontSize: 2,
  formInputBorderWidth: 0.1,
  formInputWidth: 40,
  formPlaceholderColor: '#5c5d5f',
}

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y:visible;
    font-family: ${`'${fontFamilies.join("', '")}'`};
    font-size:10px;

    ${media.greaterThan("desktop")`
      font-size:12px;
    `};
  }

  body {
    background-color: ${p => p.theme.bodyBg};
    margin:0;
  }
`;

export default theme