import React from 'react';

// ??
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// Theme
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { ThemeProvider } from 'styled-components';
import theme, { GlobalStyle } from 'components/Theme';
import 'components/Theme/index.css';

// Structure
import Annoucement from './announcement';
import Header from './header';
import Footer from './footer';

// Modals 
import Modal from './modal';
import { ModalProvider } from 'styled-react-modal'
import SpecialModalBackground from 'components/Helpers/SpecialModalBackground';

// Helpers
import cartOptions from 'components/Helpers/ShopifyBuyButtonCartOptions';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

// Contentful Live Preview
import { ContentfulLivePreviewProvider } from '@contentful/live-preview/react';

// Shopify
const ShopifyBuy = typeof window !== `undefined` ? require('@shopify/buy-button-js') : null;




const Main = styled.div`
  margin:0;
  
  ${media.lessThan("tablet")`
  margin:0;
  `}
  
  ${media.greaterThan("desktop")`
  margin:0;
  `}

  ${media.greaterThan("widescreen")`
    margin:0 auto;
    max-width:1980px;
  `}
`;

const Content = styled.div`
  
`;

const Layout = (props) => {
  if (typeof window !== `undefined`) {
    clearAllBodyScrollLocks()

    window.client = ShopifyBuy.buildClient({
      domain: 'shop.cookwithcarbon.com',
      storefrontAccessToken: 'e3759c20033fc2f49f770cb55452b28d', // previously apiKey, now deprecated
    });

    window.shopifyUI = ShopifyBuy.UI.init(window.client);

    window.shopifyUI.createComponent('cart', {
      options: cartOptions(window.shopifyUI, theme)
    })
  }

  return ( 
    <ThemeProvider theme={ theme }>
      <GlobalStyle />
      <ContentfulLivePreviewProvider locale="en-US" debugMode={true}>
        <ModalProvider backgroundComponent={SpecialModalBackground}>
          {/* <Annoucement /> */}

          <Content>  
            <Modal />
            <Header isLight={ props.whitePage } />
            <Main id="main">{ props.children }</Main>
            <Footer />
          </Content>
        </ModalProvider>
      </ContentfulLivePreviewProvider>

    </ThemeProvider>
  )
}

export default Layout