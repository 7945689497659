import styled from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal'

const SpecialModalBackground = styled(BaseModalBackground)`
  background-color: rgba(0,0,0,0.6);
  z-index:1000;
  opacity: ${props => props.opacity};
  transition: all 0.5s ease-out;
`;

export default SpecialModalBackground;