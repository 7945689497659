// Creates a Mailchimp form and submits Mailchimp group
// information for better segmenting. 
// Example:
//    Mailchimp Groups will have an ID and an index number. 
//
//    Group: Restock Notifications - ID: 84218 
//    Option: Stainless Steel - IndexNumber: 1
//    
//    Group: General - ID: 84222 
//    Option: News, Updates, & Recipes - IndexNumber: 2
//
// To see a list of group IDs and their Index Number visit this page:
// https://us2.admin.mailchimp.com/lists/integration/embeddedcode?id=972766
// Check the HTML and update Carbon's codebase accordingly. 
// 
// Usage:
//    <InlineMailchimpForm groupID="84222" groupOption="2">Get in touch</InlineMailchimpForm>

import React, { Component } from 'react'
import styled from 'styled-components';
import { media } from 'components/Theme/Styles';
import { ThemeButton } from 'components/Theme/Button';
import FormTag from 'components/Page/Forms/Form';

const EmailButton = styled(ThemeButton)`
  font-size:1.2rem;
  letter-spacing:0.1rem;
  padding:1rem 2rem;
  position:relative;
  ${media.between("tablet", "desktop")`
    margin-top:1rem;
  `};

  ${media.lessThan("tablet")`
    margin-top:1rem;
    margin-left:0;
    margin-bottom:2rem;
    font-size:1rem;
  `};
`;

const EmailTextField = styled.input`
  font-size:1.4rem;
  line-height:3.4rem;
  border:0;
  background-color:${p => p.theme.white};
  border:0.1rem solid ${p => p.theme.grey};
  text-indent:0.5rem;
  position:relative;

  ${media.between("tablet", "desktop")`
    margin-right:0;
  `};
`;


class InlineMailchimpForm extends Component {
  render() {
    return (
      <FormTag name="Newsletter Signup" successPage="/email-subscribe-confirmation">
        <EmailTextField type="email" name="email" placeholder="Email Address" required />
        <EmailButton>{this.props.children || "Notify Me"}</EmailButton>
      </FormTag>
    )
  }
}


export default InlineMailchimpForm