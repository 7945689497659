// README ---------
// If you make changes to button style, you must also replicate 
// those changes in BuyButton.js

import React, { Component } from 'react';
import { Link } from 'gatsby';
import { media } from './Styles';
import styled, { css } from 'styled-components';

const fontColor = css`
  ${ p => {
    return p.invert ? p.theme.black : p.theme.white
  }
}`;

const ButtonLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  outline:none;
  position:relative;
`;

const TextLink = styled.div`
  font-size:1.8rem;
  color: ${p => p.theme.brandPrimary};
`;

export const ButtonCss = css`
  text-transform:uppercase;
  background-color:${p => p.invert ? p.theme.black : p.theme.brandPrimary};
  color:${p => p.invert ? p.theme.white : '#322301' };
  padding:${p => p.small ? '1rem 2rem' : '2rem 3rem'};
  box-shadow: 0 0.4rem 1.5rem 0.2rem rgba(0, 0, 0, .2);
  font-size:${p => p.small ? '1.2rem' : '1.4rem'};
  font-weight:bold;
  white-space: nowrap;
  cursor:pointer;
  font-family:Poppins;
  transition:transform .2s, background-color .2s;
  &:hover, &:focus, &:active {
    -webkit-transform: translate3d(0, -2px, 0);
    transform: translate3d(0, -2px, 0);
    background-color:${p => p.invert ? p.theme.black : p.theme.brandPrimary + 'df'};
  }
  &:focus {
    outline:0;
  }
  &:disabled {
    background-color:${p => p.theme.lightGrey};
    color:${p => p.theme.darkGrey};
    cursor: not-allowed;
  }
  ${media.lessThan("tablet")`
    font-size: ${p => p.small ? 1.2 : 1.4}rem;
  `}
`;

export const ThemeButton = styled.button`
  ${ButtonCss}
`;

const ButtonAppearance = (p) => (
  <ThemeButton onClick={p.onClick} disabled={p.disabled || false} small={p.small} transparent={p.transparent} invert={p.invert} aria-label={p.children} className={p.className}>
    {p.children}
  </ThemeButton>
)

const TextAppearance = (p) => (
  <TextLink>
    {p.children}
  </TextLink>
)

// ThemeButton
class Button extends Component {

  render() {
    const { onClick, text } = this.props;

    return (
      <ButtonLink onClick={onClick} to={ this.props.to }>
        {text ? <TextAppearance {...this.props} /> : <ButtonAppearance {...this.props} /> }
      </ButtonLink>
    )
  }
}

export default Button;
