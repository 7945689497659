import React, { Component } from 'react';
import styled from 'styled-components';
import qs from 'qs';

const Wrapper = styled.div`
  margin-bottom:${p => p.direction === 'Vertical' ? '10rem' : ''};
`;

class Form extends Component {
  state = {
    success: false,
    error: null,
    errorMsg: null
  }

  UNSAFE_componentWillMount() {
    if (typeof window === 'object') {
      this.params = qs.parse(window.location.search.replace('?', ''))
    }
  }

  render() {
    const { name, successPage } = this.props;

    return (
      <Wrapper>
        <form data-netlify="true"
              name={name}
              method="POST"
              netlify-honeypot="fillmeout"
              action={successPage} netlify="true">

            <input type="hidden" name="form-name" value={name} />
            <input type="hidden" data-tracking-name="utm_source" name="utm_source" />
            <input type="hidden" data-tracking-name="utm_campaign" name="utm_campaign" />
            <input type="hidden" data-tracking-name="utm_term" name="utm_term" />
            <input type="hidden" data-tracking-name="utm_medium" name="utm_medium" />
            <input type="hidden" data-tracking-name="utm_content" name="utm_content" />
            <div hidden><input name="fillmeout"/></div>

            <div>
              { this.props.children }
            </div>
          </form>
      </Wrapper>

    )
  }
}

export default Form;


