const cartOptions = (ui, theme) => {
  return {
    cart: {
      "events":{
        "openCheckout": function (cart) {
          const webUrl = cart.viewData.webUrl;
          const checkoutUrl = webUrl.split('/');
          const token = checkoutUrl[checkoutUrl.length - 1].split('?');
          const checkout_token = token[0];

          
          // Refersion Code
          // Sends conversion session for affialiate payments.
          const rfsn = {
            cart: checkout_token,
            id: localStorage.getItem("rfsn_v4_id"),
            url: window.location.href,
            aid: localStorage.getItem("rfsn_v4_aid"),
            cs: localStorage.getItem("rfsn_v4_cs")
          };

          window.r?.sendCheckoutEvent(rfsn.cart, rfsn.id, rfsn.url, rfsn.aid, rfsn.cs);
        }
      },
      styles: {
        button: {
          "font-family": "Muli, sans-serif",
          "outline" : "none",
          ":hover": {
            "background-color": theme.brandPrimary
          },
          "background-color": theme.brandPrimary,
          ":focus": {
            "background-color": theme.brandPrimary,
            "outline" : "none"
          }
        }
      },
      text: {
        total: "Subtotal",
        button: "Checkout"
      },
      popup: false,
      googleFonts: [
        "Lato"
      ]
    },
    toggle: {
      styles: {
        toggle: {
          "display": "none",
          "font-family": "Lato, sans-serif",
          "background-color": theme.brandPrimary,
          "outline" : "none",
          ":hover": {
            "background-color": theme.brandPrimary
          },
          ":focus": {
            "background-color": theme.brandPrimary,
            "outline" : "none"
          }
        }
      },
      googleFonts: [
        "Lato"
      ]
    }
  }
}

export default cartOptions;