import styled from 'styled-components';

// Hero 

export const h1 = styled.h1`
  color:${p => p.theme.cardTextColor};
  font-size:6rem;
  font-weight:200;
  letter-spacing:-0.02em;
  margin:0;
  padding:0;
  font-family:Opti;
  text-transform:uppercase;
`;

export const h2 = styled.h2`
  color:${p => p.theme.cardTextColor};
  font-size:1.85rem;
  text-transform:uppercase;
  font-weight:300;
  letter-spacing:0.1em;
  margin:0;
  padding:0;
  font-family:Opti;
  text-transform:uppercase;
`;

export const h3 = styled.h3`
  color:${p => p.theme.cardTextColor};
  font-size:1.85rem;
  margin-bottom:2.5rem;
  line-height:3rem;
  font-weight:bold;
  text-transform:uppercase;
  letter-spacing:0.1em;
  margin:0;
  padding:0;
  font-family:Poppins;
`;

export const h4 = styled.h4`
  color:${p => p.theme.cardTextColor};
  font-size:4rem;
  font-weight:bold;
  letter-spacing:-0.02em;
  margin:0;
  padding:0;
  font-family:Poppins;
`;

export const h5 = styled.h4`
  color:${p => p.theme.cardTextColor};
  font-size:1.85rem;
  font-weight:bold;
  letter-spacing:-0.02em;
  margin:0;
  padding:0;
  font-family:Poppins;
`;

export const body = styled.div`
  line-height:4rem;
  font-size:2.2rem;
  color:${p => p.theme.cardTextColor};
  font-weight:300;
  font-family:Poppins;
  margin:0;
  padding:0;
`;